.s-landingGates {
  padding: 3em 4.5em;
  display: flex;
  flex-direction: column;
  // flex: 1;
  width: 80vw;
  height: 80vh;
  &__row {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 1em;
      min-height: 7em;
      height: 25%;
    }
    &:first-child {
      min-height: 15em;
      height: 45%;
    }
    &:last-child {
      min-height: 6rem;
      max-height: 7rem;
      height: 25%;
    }
    @media (max-width: 32em) {
      &:last-child {
        height: 100%;
      }
    }
    @include media-lg {
      flex-direction: row;
      &:nth-child(2) {
        // flex: 1;
        min-height: 9em;
        height: 30%;
      }
    }
  }
  &__col {
    // background: #3b444c;
    border-radius: 2px;
    align-items: center;
    // min-height: 20em;
    margin-bottom: 1em;
    // padding-left: 1.5em;
    transition: transform 0.25s;
    display: flex;
    position: relative;
    overflow: hidden;
    @include media-lg {
      margin-bottom: 0;
      margin-right: 1em;
    }
    &:last-child {
      margin-bottom: 0;
      @include media-lg {
        margin-right: 0;
      }
    }
    &__title {
      margin: 0;
      z-index: 100;
      color: white;
      margin-bottom: 1.5rem;
      font-size: 1.75em;
      line-height: 1.154;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
    }
    &__name {
      font-size: 1em;
      color: white;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    &__bg {
      position: absolute;
      max-width: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.05);
      transition: transform 250ms;
      transition-timing-function: ease-out;
      background-size: cover;
      // &:nth-child(2) {
      //   max-width: 13vw;
      // }
      // &:nth-child(3) {
      //   max-width: 16vw;
      // }
    }
    &:hover {
      .s-landingGates__col__bg {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    &--disabled {
      &:hover {
        .s-landingGates__col__bg {
          transform: none;
        }
        cursor: default;
      }
      background: linear-gradient(25deg, rgb(56, 56, 56) 0%, rgb(135, 135, 135) 100%);
      .s-landingGates__col__title {
      }
    }
  }
  &__submenu {
    // margin-top: 0.5em;
    ul {
      justify-content: flex-end;
      margin-top: 0.5em;
      li {
        a {
          color: #fff;
          align-items: center;
          display: flex;
          font-weight: 300;
          font-size: 0.875em;
        }
      }
    }
    svg {
      fill: #fff;
      width: 1em;
      height: 1em;
      margin-right: 0.25em;
    }
  }
  @media (max-width: 32em) {
    .l-1\/2,
    .l-1\/3 {
      width: 100%;
    }
    overflow: scroll;
    display: block;
  }
}
