.pg-brandFURY {
  &__logo {
    padding: 12vw 9vw;
    // border: 1px solid black;
    border-bottom: 2px solid white;
    transition: background 350ms;
    @include media-lg {
      padding: 8em 6em;
    }
  }
  &__logoSelect {
    // border-top: 1px solid black;
    .e-logoFURY {
      max-width: 10em;
      margin: 0 auto;
    }
    .l-row__col {
      padding: 1em !important;
      opacity: 0.25;
      transition: opacity 350ms;
      border-right: 2px solid white;
      &:last-of-type {
        border-right: none;
      }
      &:hover {
        opacity: 0.6;
      }
      &.-active {
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }
    }
    > * {
      padding: 0.5em 1em;
      // border: 1px solid black;
      border-top: none;
    }
    svg {
      width: 100%;
    }

    &__barContainer {
      position: relative;
      margin-top: 5px;
      transition: left 500ms cubic-bezier(0, 0, 0, 1.04);
      width: 25%;
      border: 0;
      padding: 0;
      &__bar {
        background: #c8102e;
        background-position: center;
        height: 4px;
        position: relative;
        transform: scale(1);
        opacity: 0.35;
      }
    }

    // SVGS
    &__dot {
      margin: -0.75em 0em;
      position: absolute;
      width: 0.75em !important;
      height: 1em !important;
      left: -0.3125em;
      opacity: 1;
      transform-origin: center;
    }
    &__donut {
      margin: -0.75em 0em;
      position: absolute;
      width: 0.75em !important;
      height: 1em !important;
      right: -0.3125em;
      opacity: 1;
      transform-origin: center;
      z-index: 200;
    }
  }
  .u-txtSmall {
    font-size: 0.875em;
  }
}
